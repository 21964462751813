import { h } from 'vue';
import { createRouter, createWebHistory, RouteRecordRaw, RouterView } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import AuthService from "@/core/services/AuthService";
import { tricklingProgress } from '@/core/plugins/trickling-progress';

const routes: Array<RouteRecordRaw> = [
    // routes using main layout with authentication
    {
        path: "/",
        redirect: "/dashboard",
        component: () => import("@/layouts/main-layout/MainLayout.vue"),
        meta: {
            middleware: "auth",
        },
        children: [
            {
                path: "/dashboard",
                name: "dashboard",
                component: () => import("@/views/pages/dashboard/Dashboard.vue"),
                meta: {
                    pageTitle: "Dashboard",
                    breadcrumbs: ["Dashboards"],
                    userFeature: null,
                },
            },
            // Front Office
            {
                path: "/front-office/booking-chart",
                name: "booking-chart",
                component: () => import("@/views/pages/front-office/booking/Chart.vue"),
                meta: {
                    pageTitle: "Booking Chart",
                    breadcrumbs: ["Front Office", "Booking Chart"],
                    userFeature: { featureName: 'booking_chart', metaName: 'can_view' },
                },
            },
            {
                path: "/front-office/booking",
                component: { render: () => h(RouterView) },
                children: [
                    {
                        path: '',
                        name: "booking",
                        component: () => import("@/views/pages/front-office/booking/Booking.vue"),
                        meta: {
                            pageTitle: "Booking List",
                            breadcrumbs: ["Front Office", "Booking List"],
                            userFeature: { featureName: 'booking_list', metaName: 'can_view' },
                        },
                    },
                    {
                        path: 'create',
                        name: "booking-create",
                        component: () => import("@/views/pages/front-office/booking/Create.vue"),
                        meta: {
                            pageTitle: "Create New Booking",
                            breadcrumbs: ["Front Office", "Booking", "Create New Booking"],
                            userFeature: { featureName: 'booking_list', metaName: 'can_add' },
                        },
                    },
                ],
            },
            {
                path: "/front-office/room-available",
                name: "room-available",
                component: () => import("@/views/pages/test/Test.vue"),
                meta: {
                    pageTitle: "Room Available",
                    breadcrumbs: ["Front Office", "Room Available"],
                    userFeature: { featureName: 'room_available', metaName: 'can_view' },
                },
            },
            {
                path: "/front-office/guest-profile",
                name: "guest-profile",
                component: () => import("@/views/pages/front-office/guest-profile/GuestProfile.vue"),
                meta: {
                    pageTitle: "Guest Profile",
                    breadcrumbs: ["Front Office", "Guest Profile"],
                    userFeature: { featureName: 'guest_profile', metaName: 'can_view' },
                },
            },
            {
                path: "/front-office/payment-receive",
                name: "payment-receive",
                component: () => import("@/views/pages/front-office/payment-receive/PaymentReceive.vue"),
                meta: {
                    pageTitle: "Payment Receive",
                    breadcrumbs: ["Front Office", "Payment Receive"],
                    userFeature: { featureName: 'payment_recieve', metaName: 'can_view' },
                },
            },
            {
                path: "/front-office/night-audit",
                name: "night-audit",
                component: () => import("@/views/pages/front-office/night-audit/NightAudit.vue"),
                meta: {
                    pageTitle: "Night Audit",
                    breadcrumbs: ["Front Office", "Night Audit"],
                    userFeature: { featureName: 'night_audit', metaName: 'can_view' },
                },
            },
            {
                path: "/front-office/guest-list-daily",
                name: "guest-list-daily",
                component: () => import("@/views/pages/front-office/guest-list-daily/GuestListDaily.vue"),
                meta: {
                    pageTitle: "Guest List Daily",
                    breadcrumbs: ["Front Office", "Guest List Daily"],
                    userFeature: { featureName: 'guest_list_daily', metaName: 'can_view' },
                },
            },
            // Back Office
            {
                path: "/back-office/chart-of-account",
                name: "chart-of-account",
                component: () => import("@/views/pages/back-office/chart-of-account/ChartofAccount.vue"),
                meta: {
                    pageTitle: "Chart of Account",
                    breadcrumbs: ["Back Office", "Chart of Account"],
                    userFeature: { featureName: 'chart_of_account', metaName: 'can_view' },
                },
            },
            {
                path: "/back-office/tax-and-service-list",
                name: "tax-and-service-list",
                component: () => import("@/views/pages/back-office/tax-and-service-list/TaxnServiceList.vue"),
                meta: {
                    pageTitle: "Tax And Service List",
                    breadcrumbs: ["Back Office", "Tax And Service List"],
                    userFeature: { featureName: 'tax_and_service_list', metaName: 'can_view' },
                },
            },
            {
                path: "/back-office/agent-property-list",
                name: "agent-property-list",
                component: () => import("@/views/pages/back-office/agent-property-list/AgentPropertyList.vue"),
                meta: {
                    pageTitle: "Agent Property List",
                    breadcrumbs: ["Back Office", "Agent Property List"],
                    userFeature: { featureName: 'agent_property_list', metaName: 'can_view' },
                },
            },
            {
                path: "/back-office/payment-options-list",
                component: { render: () => h(RouterView) },
                children: [
                    {
                        path: '',
                        name: "payment-options-list",
                        component: () => import("@/views/pages/back-office/payment-options-list/PaymentOptionsList.vue"),
                        meta: {
                            pageTitle: "Payment Options List",
                            breadcrumbs: ["Back Office", "Payment Options List"],
                            userFeature: { featureName: 'payment_options_list', metaName: 'can_view' },
                        },
                    },
                    {
                        path: 'payment-methods-list/:id',
                        name: 'payment-options-list-payment-methods',
                        component: () => import("@/views/pages/back-office/payment-options-list/PaymentMethodList.vue"),
                        meta: {
                            pageTitle: "Payment Methods List",
                            breadcrumbs: ["Back Office", "Payment Options List", "Payment Methods List"],
                            userFeature: { featureName: 'payment_options_list', metaName: 'can_view' },
                        },
                    },
                ]
            },
            {
                path: "/back-office/bill-type-coa-list",
                name: "bill-type-coa-list",
                component: () => import("@/views/pages/back-office/bill-type-list/BillTypeList.vue"),
                meta: {
                    pageTitle: "Bill Type List",
                    breadcrumbs: ["Back Office", "Bill Type List"],
                    userFeature: { featureName: 'bill_type_coa_list', metaName: 'can_view' },
                },
            },

            {
                path: "/back-office/account-receivable/ar-aging-list",
                name: "ar-aging-list",
                component: () => import("@/views/pages/test/Test.vue"),
                meta: {
                    pageTitle: "Account Receivable Aging List",
                    breadcrumbs: ["Back Office", "Account Receivable", "AR Aging List"],
                    userFeature: { featureName: 'accounts_receivable_aging_list', metaName: 'can_view' },
                },
            },
            {
                path: "/back-office/account-receivable/ar-payment-list",
                name: "ar-payment-list",
                component: () => import("@/views/pages/test/Test.vue"),
                meta: {
                    pageTitle: "Account Receivable Payment List",
                    breadcrumbs: ["Back Office", "Account Receivable", "AR Payment List"],
                    userFeature: { featureName: 'accounts_receivable_payment_list', metaName: 'can_view' },
                },
            },
            {
                path: "/back-office/account-receivable/ar-journal-manual",
                name: "ar-journal-manual",
                component: () => import("@/views/pages/test/Test.vue"),
                meta: {
                    pageTitle: "Account Receivable Journal Manual",
                    breadcrumbs: ["Back Office", "Account Receivable", "AR Journal Manual"],
                    userFeature: { featureName: 'accounts_receivable_journal_manual', metaName: 'can_view' },
                },
            },
            {
                path: "/back-office/account-receivable/ar-journal",
                name: "ar-journal",
                component: () => import("@/views/pages/test/Test.vue"),
                meta: {
                    pageTitle: "Account Receivable Journal",
                    breadcrumbs: ["Back Office", "Account Receivable", "AR Journal"],
                    userFeature: { featureName: 'accounts_receivable_journal', metaName: 'can_view' },
                },
            },

            {
                path: "/back-office/account-payable/supplier-list",
                name: "ap-supplier-list",
                component: () => import("@/views/pages/test/Test.vue"),
                meta: {
                    pageTitle: "Supplier List",
                    breadcrumbs: ["Back Office", "Account Payable", "Supplier List"],
                    userFeature: { featureName: 'account_payable_supplier_list', metaName: 'can_view' },
                },
            },
            {
                path: "/back-office/account-payable/expenses",
                name: "ap-expenses",
                component: () => import("@/views/pages/test/Test.vue"),
                meta: {
                    pageTitle: "Expenses",
                    breadcrumbs: ["Back Office", "Account Payable", "Expenses"],
                    userFeature: { featureName: 'expenses', metaName: 'can_view' },
                },
            },
            {
                path: "/back-office/account-payable/ap-aging-list",
                name: "ap-aging-list",
                component: () => import("@/views/pages/test/Test.vue"),
                meta: {
                    pageTitle: "Account Payable Aging List",
                    breadcrumbs: ["Back Office", "Account Payable", "AP Aging List"],
                    userFeature: { featureName: 'account_payable_aging_list', metaName: 'can_view' },
                },
            },
            {
                path: "/back-office/account-payable/ap-payment-list",
                name: "ap-payment-list",
                component: () => import("@/views/pages/test/Test.vue"),
                meta: {
                    pageTitle: "Account Payable Payment List",
                    breadcrumbs: ["Back Office", "Account Payable", "AP Payment List"],
                    userFeature: { featureName: 'account_payable_payment_list', metaName: 'can_view' },
                },
            },
            {
                path: "/back-office/account-payable/ap-journal-manual",
                name: "ap-journal-manual",
                component: () => import("@/views/pages/test/Test.vue"),
                meta: {
                    pageTitle: "Account Payable Journal Manual",
                    breadcrumbs: ["Back Office", "Account Payable", "AP Journal Manual"],
                    userFeature: { featureName: 'account_payable_journal_manual', metaName: 'can_view' },
                },
            },
            {
                path: "/back-office/account-payable/ap-journal",
                name: "ap-journal",
                component: () => import("@/views/pages/test/Test.vue"),
                meta: {
                    pageTitle: "Account Payable Journal",
                    breadcrumbs: ["Back Office", "Account Payable", "AP Journal"],
                    userFeature: { featureName: 'account_payable_journal', metaName: 'can_view' },
                },
            },
            // Property Setup
            {
                path: "/property-setup/room-status-ist",
                name: "room-status-list",
                component: () => import("@/views/pages/room-setup/room-status-list/RoomStatusList.vue"),
                meta: {
                    pageTitle: "Room Status List",
                    breadcrumbs: ["Property Setup", "Room Status List"],
                    userFeature: { featureName: 'room_status_list', metaName: 'can_view' },
                },
            },
            {
                path: "/property-setup/room-bed-type-list",
                name: "room-bed-type-list",
                component: () => import("@/views/pages/room-setup/room-bed-type-list/RoomBedTypeList.vue"),
                meta: {
                    pageTitle: "Room Bed Type List",
                    breadcrumbs: ["Property Setup", "Room Bed Type List"],
                    userFeature: { featureName: 'room_bed_type_list', metaName: 'can_view' },
                },
            },
            {
                path: '/property-setup/room-types',
                component: { render: () => h(RouterView) },
                children: [
                    {
                        path: '',
                        name: "room-types",
                        component: () => import("@/views/pages/room-setup/room-types/RoomTypes.vue"),
                        meta: {
                            pageTitle: "Room Types",
                            breadcrumbs: ["Property Setup", "Room Types"],
                            userFeature: { featureName: 'room_type', metaName: 'can_view' },
                        },
                    },
                    {
                        path: 'add',
                        name: 'room-types-add',
                        component: () => import("@/views/pages/room-setup/room-types/Add.vue"),
                        meta: {
                            pageTitle: "Add New Room Type",
                            breadcrumbs: ["Property Setup", "Room Types", "Add New Room Type"],
                            userFeature: { featureName: 'room_type', metaName: 'can_add' },
                        },
                    },
                    {
                        path: 'edit/:id',
                        name: "room-types-edit",
                        component: () => import("@/views/pages/room-setup/room-types/Edit.vue"),
                        meta: {
                            pageTitle: "Edit Room Type",
                            breadcrumbs: ["Property Setup", "Room Types", "Edit Room Type"],
                            userFeature: { featureName: 'room_type', metaName: 'can_edit' },
                        },
                    },
                    {
                        path: 'room-list/:id',
                        name: "room-types-room-list",
                        component: () => import("@/views/pages/room-setup/room-types/room-list/RoomList.vue"),
                        meta: {
                            pageTitle: "Room List",
                            breadcrumbs: ["Property Setup", "Room Types", "Edit Room Type", "Room List"],
                            userFeature: { featureName: 'rooms_list', metaName: 'can_view' },
                        },
                    },
                ]
            },
            {
                path: '/property-setup/room-rates',
                component: { render: () => h(RouterView) },
                children: [
                    {
                        path: '',
                        name: "room-rates",
                        component: () => import("@/views/pages/room-setup/room-rates/RoomRates.vue"),
                        meta: {
                            pageTitle: "Room Rates",
                            breadcrumbs: ["Property Setup", "Room Rates"],
                            userFeature: { featureName: 'room_rates', metaName: 'can_view' },
                        },
                    },
                    {
                        path: 'edit/:id',
                        name: "room-rates-edit",
                        component: () => import("@/views/pages/room-setup/room-rates/Edit.vue"),
                        meta: {
                            pageTitle: "Edit Room Rate",
                            breadcrumbs: ["Property Setup", "Room Rates", "Edit Room Rate"],
                            userFeature: { featureName: 'room_rates', metaName: 'can_edit' },
                        },
                    },
                ],
            },
            // System Setup
            {
                path: "/system-setup/feature-category",
                name: "feature-category",
                component: () => import("@/views/pages/system-setup/feature-category/FeatureCategory.vue"),
                meta: {
                    pageTitle: "Feature Category",
                    breadcrumbs: ["System Setup", "Features", "Feature Category"],
                    userFeature: { featureName: 'features_categories', metaName: 'can_view' },
                },
            },
            {
                path: "/system-setup/feature",
                name: "feature",
                component: () => import("@/views/pages/system-setup/feature/Feature.vue"),
                meta: {
                    pageTitle: "Feature List",
                    breadcrumbs: ["System Setup", "Features", "Feature List"],
                    userFeature: { featureName: 'features', metaName: 'can_view' },
                },
            },
            {
                path: "/system-setup/property-group",
                name: "property-group",
                component: () => import("@/views/pages/system-setup/property-group/PropertyGroup.vue"),
                meta: {
                    pageTitle: "Property Group",
                    breadcrumbs: ["System Setup", "Properties", "Property Group"],
                    userFeature: { featureName: 'properties_groups', metaName: 'can_view' },
                },
            },
            {
                path: "/system-setup/property",
                name: "property",
                component: () => import("@/views/pages/system-setup/property/Property.vue"),
                meta: {
                    pageTitle: "Property List",
                    breadcrumbs: ["System Setup", "Properties", "Property List"],
                    userFeature: { featureName: 'properties_settings', metaName: 'can_view' },
                },
            },
            {
                path: "/system-setup/user-feature-category-default",
                name: "user-feature-category-default",
                component: () => import("@/views/pages/system-setup/user-category-feature-default/UserCategoryFeatureDefault.vue"),
                meta: {
                    pageTitle: "User Feature Category Default",
                    breadcrumbs: ["System Setup", "Features", "User Feature Category Default"],
                    userFeature: { featureName: 'user_category_features_defaults', metaName: 'can_view' },
                },
            },
            {
                path: '/system-setup/users',
                component: { render: () => h(RouterView) },
                children: [
                    {
                        path: '',
                        name: 'users',
                        component: () => import("@/views/pages/system-setup/user/User.vue"),
                        meta: {
                            pageTitle: "Users",
                            breadcrumbs: ["System Setup", "Users"],
                            userFeature: { featureName: 'users_settings', metaName: 'can_view' },
                        },
                    },
                    {
                        path: 'add',
                        name: 'users-add',
                        component: () => import("@/views/pages/system-setup/user/Add.vue"),
                        meta: {
                            pageTitle: "Add New User",
                            breadcrumbs: ["System Setup", "Users", "Add New User"],
                            userFeature: { featureName: 'users_settings', metaName: 'can_add' },
                        },
                    },
                    {
                        path: 'edit/:id',
                        name: "users-edit",
                        component: () => import("@/views/pages/system-setup/user/Edit.vue"),
                        meta: {
                            pageTitle: "Edit User",
                            breadcrumbs: ["System Setup", "Users", "Edit User"],
                            userFeature: { featureName: 'users_settings', metaName: 'can_edit' },
                        },
                    }
                ]
            },
            {
                path: "/system-setup/room-status-default",
                name: "room-status-default",
                component: () => import("@/views/pages/system-setup/room-status-default/RoomStatusDefault.vue"),
                meta: {
                    pageTitle: "Room Status Default",
                    breadcrumbs: ["System Setup", "Property Default Setup", "Room Status Default"],
                    userFeature: { featureName: 'room_status_default', metaName: 'can_view' },
                },
            },
            {
                path: "/system-setup/amenities-list-default",
                name: "amenities-list-default",
                component: () => import("@/views/pages/system-setup/amenities-list-default/AmenitiesListDefault.vue"),
                meta: {
                    pageTitle: "Amenities List Default",
                    breadcrumbs: ["System Setup", "Property Default Setup", "Amenities List Default"],
                    userFeature: { featureName: 'amenities_list_default', metaName: 'can_view' },
                },
            },
            {
                path: "/system-setup/chart-of-account-default",
                name: "chart-of-account-default",
                component: () => import("@/views/pages/system-setup/chart-of-account-default/ChartofAccountDefault.vue"),
                meta: {
                    pageTitle: "Chart of Account Default",
                    breadcrumbs: ["System Setup", "Property Default Setup", "Chart of Account Default"],
                    userFeature: { featureName: 'chart_of_account_default', metaName: 'can_view' },
                },
            },
            {
                path: "/system-setup/chart-of-account-template",
                component: { render: () => h(RouterView) },
                children: [
                    {
                        path: '',
                        name: "chart-of-account-template",
                        component: () => import("@/views/pages/system-setup/chart-of-account-template/ChartofAccountTemplate.vue"),
                        meta: {
                            pageTitle: "Chart of Account Template",
                            breadcrumbs: ["System Setup", "Property Default Setup", "Chart of Account Template"],
                            userFeature: { featureName: 'chart_of_account_template', metaName: 'can_view' },
                        },
                    },
                    {
                        path: 'list/:id',
                        name: "chart-of-account-template-list",
                        component: () => import("@/views/pages/system-setup/chart-of-account-template/ChartofAcountList.vue"),
                        meta: {
                            pageTitle: "List Chart of Account Template",
                            breadcrumbs: ["System Setup", "Property Default Setup", "Chart of Account Template", "List Chart of Account Template"],
                            userFeature: { featureName: 'chart_of_account_template', metaName: 'can_list' },
                        },
                    },

                ],
            },
            {
                path: "/system-setup/bill-type-default",
                name: "bill-type-default",
                component: () => import("@/views/pages/system-setup/bill-type/BillType.vue"),
                meta: {
                    pageTitle: "Bill Type Default",
                    breadcrumbs: ["System Setup", "Property Default Setup", "Bill Type Default"],
                    userFeature: { featureName: 'bill_type_default', metaName: 'can_view' },
                },
            },
            // General Settings
            {
                path: "/general-settings/country-settings",
                name: "country-settings",
                component: () => import("@/views/pages/general-setting/country/Country.vue"),
                meta: {
                    pageTitle: "Country Settings",
                    breadcrumbs: ["General Settings", "Country Settings"],
                    userFeature: { featureName: 'country_settings', metaName: 'can_view' },
                },
            },
            {
                path: "/general-settings/region-settings",
                name: "region-settings",
                component: () => import("@/views/pages/general-setting/region/Region.vue"),
                meta: {
                    pageTitle: "Region Settings",
                    breadcrumbs: ["General Settings", "Region Settings"],
                    userFeature: { featureName: 'region_settings', metaName: 'can_view' },
                },
            },
            {
                path: "/general-settings/area-settings",
                name: "area-settings",
                component: () => import("@/views/pages/general-setting/area/Area.vue"),
                meta: {
                    pageTitle: "Area Settings",
                    breadcrumbs: ["General Settings", "Area Settings"],
                    userFeature: { featureName: 'area_settings', metaName: 'can_view' },
                },
            },
            {
                path: "/general-settings/language-settings",
                name: "language-settings",
                component: () => import("@/views/pages/general-setting/language/Language.vue"),
                meta: {
                    pageTitle: "Language Settings",
                    breadcrumbs: ["General Settings", "Language Settings"],
                    userFeature: { featureName: 'language_settings', metaName: 'can_view' },
                },
            },
            {
                path: "/general-settings/currency-settings",
                name: "currency-settings",
                component: () => import("@/views/pages/general-setting/currency/Currency.vue"),
                meta: {
                    pageTitle: "Currency Settings",
                    breadcrumbs: ["General Settings", "Currency Settings"],
                    userFeature: { featureName: 'currency_settings', metaName: 'can_view' },
                },
            },
            {
                path: "/general-settings/timezone-settings",
                name: "timezone-settings",
                component: () => import("@/views/pages/general-setting/timezone/Timezone.vue"),
                meta: {
                    pageTitle: "Timezone Settings",
                    breadcrumbs: ["General Settings", "Timezone Settings"],
                    userFeature: { featureName: 'timezone_settings', metaName: 'can_view' },
                },
            },
            {
                path: "/account-overview",
                name: "account-overview",
                component: () => import("@/views/pages/account/Account.vue"),
                meta: {
                    pageTitle: "Account Overview",
                    breadcrumbs: ["Account", "Overview"],
                    userFeature: null,
                },
            },
            {
                path: "/account-update",
                name: "account-update",
                component: () => import("@/views/pages/account/Edit.vue"),
                meta: {
                    pageTitle: "Account Update",
                    breadcrumbs: ["Account", "Update"],
                    userFeature: null,
                },
            },
        ],
    },
    // routes using auth layout
    {
        path: "/",
        component: () => import("@/layouts/AuthLayout.vue"),
        children: [
            {
                path: "/sign-in",
                name: "sign-in",
                component: () =>
                    import("@/views/pages/authentication/SignIn.vue"),
                meta: {
                    pageTitle: "Sign In",
                    userFeature: null,
                },
            },
            {
                path: "/sign-up",
                name: "sign-up",
                component: () =>
                    import("@/views/pages/authentication/SignUp.vue"),
                meta: {
                    pageTitle: "Sign Up",
                    userFeature: null,
                },
            },
            {
                path: "/password-reset",
                name: "password-reset",
                component: () =>
                    import("@/views/pages/authentication/PasswordReset.vue"),
                meta: {
                    pageTitle: "Password reset",
                    userFeature: null,
                },
            },
        ],
    },
    // only multi step sign up
    {
        path: "/multi-step-sign-up",
        name: "multi-step-sign-up",
        component: () =>
            import("@/views/pages/authentication/MultiStepSignUp.vue"),
        meta: {
            pageTitle: "Multi-step Sign up",
            userFeature: null,
        },
    },
    // routes using system layout
    {
        path: "/",
        component: () => import("@/layouts/SystemLayout.vue"),
        children: [
            {
                // the 404 route, when none of the above matches
                path: "/404",
                name: "404",
                component: () => import("@/views/pages/basic-flow/Error404.vue"),
                meta: {
                    pageTitle: "Error 404",
                    userFeature: null,
                },
            },
            {
                path: "/500",
                name: "500",
                component: () => import("@/views/pages/basic-flow/Error500.vue"),
                meta: {
                    pageTitle: "Error 500",
                    userFeature: null,
                },
            },
        ],
    },
    // handle redirection missmatch route
    {
        path: "/:pathMatch(.*)*",
        redirect: "/404",
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    // current page view title
    document.title = `${to.meta.pageTitle} - ${import.meta.env.VITE_NAME}`;

    // reset config to initial state
    store.commit(Mutations.RESET_LAYOUT_CONFIG);

    Promise.resolve(
        // verify auth token before each page change
        store.dispatch(Actions.VERIFY_AUTH, { token: JwtService.getToken() })
    ).finally(() => {
        // before page access check if page requires authentication
        if (to.meta.middleware === "auth") {
            if (store.getters.isUserAuthenticated) {
                next();
            } else {
                next({ name: "sign-in" });
            }
        } else {
            next();
        }
    });

    // Scroll page to top on every route change
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
    });
});

router.beforeResolve((to, from, next) => {
    if (to.name) {
        tricklingProgress.start();
    }
    // before page resolved check if the page is active
    if (to.meta.userFeature) {
        const isUserFeature = AuthService.isUserFeature(to.meta.userFeature['featureName'], to.meta.userFeature['metaName']);
        if (isUserFeature) {
            next();
        } else {
            next({ name: "404" });
        }
    } else {
        next();
    }
});

router.afterEach((to, from) => {
    tricklingProgress.done();
});

export default router;
